<x-data-table [view]="view" [selectable]="false">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>

  <ng-template xDataColumnDef="sentAt" let-row>
    {{ row.sentAt | datetime: 'medium' }}
  </ng-template>

  <ng-template xDataColumnDef="channelName" let-row>
    {{ row.channelName }}
  </ng-template>
  <ng-template xDataColumnDef="notificationName" let-row>
    {{ row.notificationName }}
  </ng-template>
  <ng-template xDataColumnDef="recipientName" let-row>
    {{ row.recipientName }}
  </ng-template>
  <ng-template xDataColumnDef="status" let-row>
    {{ row.status }}
  </ng-template>
  <ng-template xDataColumnDef="references" let-row>
    <x-pill-group>
      <x-pill *ngFor="let ref of row.references">{{ ref.entityType }} - {{ ref.entityId }}</x-pill>
    </x-pill-group>
  </ng-template>

  <span *xDataAction="'edit'; let row; primary: true">
    <a
      x-data-button
      *ngIf="row.previewUrl"
      icon="magnifying-glass"
      href="{{ row.previewUrl }}"
      target="_blank"
      [matTooltip]="'Preview'"
    ></a>
  </span>
</x-data-table>
