import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocaleModule } from '@x/common/locale';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { NotificationLogStatusFilterFieldComponent } from './components/notification-log-index/notification-log-status-filter-field.component';
import { NotificationLogTableComponent } from './components/notification-log-table/notification-log-table.component';

@NgModule({
  declarations: [NotificationLogTableComponent, NotificationLogStatusFilterFieldComponent],
  imports: [LocaleModule, RouterModule, CommonModule, EcommerceAdminCoreModule],
  exports: [NotificationLogTableComponent, NotificationLogStatusFilterFieldComponent],
})
export class NotificationsSharedModule {}
