import { Component, Input, OnInit } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { INotificationLogRowObject } from '@x/ecommerce/domain-client';
import { NotificationLogFilterInput } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-notification-log-table',
  templateUrl: './notification-log-table.component.html',
  styleUrls: ['./notification-log-table.component.scss'],
  host: {
    class: 'x-notification-log-table',
  },
})
export class NotificationLogTableComponent implements OnInit {
  @Input()
  view: DataTableView<INotificationLogRowObject, NotificationLogFilterInput, any, string>;

  constructor() {}

  ngOnInit() {}
}
